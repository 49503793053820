<template>
    <div>
        <div class="tab_wpr">
            <div class="result_wpr">
                <div class="actions">
                    <ul>
                        <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                            {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                            <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="playbook-user-per-page-filter" />
                        </li>
                        <li>
                            <input type="text" placeholder="Search" :disabled="playbookUserLoader" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                            <i class="fas fa-search"></i>
                        </li>
                        <li class="list_info">
                            {{ playbookUsers.from ? playbookUsers.from : 0 }} - {{ playbookUsers.to ? playbookUsers.to : 0 }} of <span>{{ playbookUsers.total ? playbookUsers.total : 0 }}</span>
                        </li>
                    </ul>
                </div>
                <div v-if="playbookUserLoader"><line-loader /></div>
                <table class="standard">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Completed Steps</th>
                            <th>Completed Tabs</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody v-if="playbookUsers.total">
                        <tr v-for="(user, u) of playbookUsers.data" :key="u">
                            <td></td>
                            <td>
                                <div class="user_wpr" @click="userProfile = true">
                                    <h4>{{ user.contact_name }}</h4>
                                </div>
                            </td>
                            <td>
                                <small class="text-danger" v-if="user.email_enabled == 0">[Invalid Email]</small>
                                <div class="email_wpr">{{ user.email }}</div>
                            </td>
                            <td>{{ user.stats}}</td>
                            <td class="action_wpr">
                                <button class="btn save_button btn_sm" v-if="user.tabs_completion && Object.values(user.tabs_completion).length" @click="viewTabsCompletion(user.tabs_completion, user.contact_name)">View</button>
                            </td>
                            <td>
                                <ul class="action_list">
                                    <li @click="resendPlaybook(user.contact_id)"><i class="fas fa-envelope"></i></li>
                                    <li @click="handleRestrictPlaybook(user.contact_id)"><i class="fas fa-trash-alt"></i></li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="4" class="px-4">No Records Found</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="pagination pb-4 mt-4" v-show="playbookUsers.total">
                <pagination v-model="params.page" :pages="playbookUsers.last_page" @update:modelValue="handlePagination" />
            </div>
        </div>
        <div class="modal secondary" v-if="tabsCompletionDialog" :class="{ active : tabsCompletionDialog }">
            <div class="modal_container p-0">
                <div class="modal_header border-bottom">
                    <h1 class="m-0 pb-4">Tabs Completion ({{ selectedContactName }})</h1>
                    <button class="close_btn" @click="tabsCompletionDialog = false"><i class="fas fa-times"></i></button>
                </div>
                <div class="modal_body w-100">
                    <div class="result_wpr">
                        <table class="tab-completion">
                            <thead>
                                <tr>
                                    <th style="min-width: 100px;">Step Title</th>
                                    <th>Tab Completion</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(completion, c) of tabsCompletionItems" :key="c">
                                    <td>{{ c.split('---->')[1] }}</td>
                                    <td>
                                        <ul>
                                            <li v-for="(tab, t) of completion.tabs" :key="t">
                                                {{ tab }} : {{ completion.completed_tabs[tab] || completion.completed_tabs[t] ? 'completed' : 'incomplete' }}
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <resend-login v-model="resendLogin" :playbook="playbook" :contact-id="selectedContactId" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions } from 'vuex'

    const PageFilter = defineAsyncComponent(() => import('@/components/PageFilter'))
    const LineLoader = defineAsyncComponent(() => import('@/components/LineLoader'))
    const ResendLogin = defineAsyncComponent(() => import('@/pages/playbook/components/ResendLogin'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Pagination from '@hennge/vue3-pagination'

    export default {
        name: 'Playbook User',

        data () {
            return {
                params: {
                    page: 1,
                    per_page: 5,
                    search: '',
                },
                isTyping: false,
                tabsCompletionItems: [],
                selectedContactName: '',
                selectedContactId: 0,
                tabsCompletionDialog: false,
                resendLogin: false,
            };
        },

        props: {
            playbook: Object,
        },

        components: {
            PageFilter,
            Pagination,
            LineLoader,
            ResendLogin,
        },

        watch: {
            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getPlaybookUsers(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getPlaybookUsers(vm.params);
                        }
                    }
                }
            },

            'params.per_page' () {
                const vm = this;

                vm.params.page = 1;
                vm.getPlaybookUsers(vm.params);
            },

            playbook (playbook) {
                const vm = this;

                vm.params.playbook_id = playbook.id;
                vm.getPlaybookUsers(vm.params);
            },
        },

        computed: mapState({
            playbookUsers: state => state.playbookModule.playbookUsers,
            playbookUserLoader: state => state.playbookModule.playbookUserLoader,
        }),

        mounted () {
            const vm = this;

            vm.params.playbook_id = vm.playbook.id;
            vm.getPlaybookUsers(vm.params);
        },

        methods: {
            ...mapActions({
                getPlaybookUsers: 'playbookModule/getPlaybookUsers',
                restrictPlaybook: 'playbookModule/restrictPlaybook',
            }),

            togglePerPageFilter () {
                const vm = this;
                vm.actionList = false;
                vm.bulkActionDropdown = false;
                const filter = vm.$refs['playbook-user-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['playbook-user-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getPlaybookUsers(vm.params);
            },

            resendPlaybook (contactId) {
                const vm = this;

                vm.resendLogin = true;
                vm.selectedContactId = contactId;
            },

            handleRestrictPlaybook (contactId) {
                const vm = this;
                const options = Helper.swalConfirmOptions('Are you sure?', 'This action is permanent. You will not be able to recover this user');
                      options.preConfirm = () => {
                          return  vm.restrictPlaybook({ contacts: [contactId], playbook_id: [vm.playbook.id] }).then((result) => {
                                      if (result) {
                                          vm.getPlaybookUsers(vm.params);
                                      }
                                  });
                      }

                Swal.fire(options);
            },

            viewTabsCompletion (completion, contactName) {
                const vm = this;

                vm.tabsCompletionItems  = completion;
                vm.selectedContactName  = contactName;
                vm.tabsCompletionDialog = true;
            },
        },
    }
</script>

<style scoped>
    td.action_wpr {
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0;
    }

    .mt-0 {
        margin-top: 0px;
    }

    .modal .modal_footer {
        border-radius: 12px;
    }

    .btn_sm {
        min-width: 60px;
        height: 30px;
        padding: 0 20px;
    }

    .p-0 {
        padding: 0;
    }

    .modal_container.p-0 .modal_header {
        padding: 20px 30px 0;
        width: 100%;
    }
</style>
